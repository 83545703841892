@import '../../styles/variables';
@import '../../styles/includes';

.ArtistCarousel {
    $root: &;
    height: 100%;
    font-family: $fontLight;

    &__Title {
        @extend %h2;
        margin: 32px 0 24px;

        @include media(m) {
            text-align: center;
        }
    }

    &__Container {
        @extend %container;
    }

    &__SwiperButtons {
        @extend %container;
        position: relative;
        display: flex;
        flex-direction: row;
    }

    &__TagList {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__Tag {
        margin: 0 8px 8px 0;
    }

    &__Button {
        &--Next {
            position: absolute;
            top: -73px;
            right: 16px;
            z-index: 1;
            padding: 16px;
            background-color: $colorRed50;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
            transform: rotate(-90deg);
            transition: background-color 0.2s ease-in-out;

            @include media(ml) {
                top: -80px;
                right: 0;
            }

            svg * {
                fill: white;
            }

            &:hover {
                background-color: $colorRed90;
            }
        }

        &--Prev {
            position: absolute;
            top: -73px;
            right: 95px;
            z-index: 1;
            padding: 16px;
            background-color: $colorRed50;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
            transform: rotate(90deg);
            transition: background-color 0.2s ease-in-out;

            @include media(ml) {
                top: -80px;
                right: 80px;
            }

            svg * {
                fill: white;
            }

            &:hover {
                background-color: $colorRed90;
            }
        }

        &--Disabled {
            background-color: $colorGray20;

            svg * {
                fill: black;
            }

            &:hover {
                background-color: $colorGray20;
                cursor: default;
            }
        }
    }

    &__Card {
        height: auto;
        display: flex;
        justify-content: center;
    }

    &__CardWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        row-gap: 24px;
        height: 100%;

        @include media(ml) {
            flex-direction: row;
            column-gap: 32px;
            row-gap: 0;
            padding: 0;
        }

        &:hover {
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;

            &:active {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }
    }

    &__CardImage {
        flex-shrink: 0;
        width: 100%;

        @include media(ml) {
            max-width: 50%;
        }

        img {
            position: relative !important;
            object-fit: contain;
        }
    }

    &__TextWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        width: 100%;
        overflow: hidden;
        height: 100%;

        @include media(ml) {
            row-gap: 0;
            justify-content: space-between;
        }
    }

    &__CardTitle {
        margin: 0 0 16px 0;
        font-size: 2.4rem;
        line-height: 1.52;

        @include media(ml) {
            font-size: 3.2rem;
            line-height: 1.4;
        }
    }

    &__CardText {
        margin: 17px 0 0 0;
        font-size: 1.8rem;
        line-height: 1.33;

        @include media(ml) {
            font-size: 2rem;
            line-height: 1.35;
        }
    }

    &__ButtonWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
        row-gap: 16px;

        a {
            display: inline-block;
            text-overflow: ellipsis;
            width: 100%;
            max-width: fit-content;
        }

        button {
            cursor: pointer;
        }

        @include media(l) {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 2px;
        }
    }
}
