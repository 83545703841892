@import '../../styles/variables';

.Tag {
    display: block;
    width: fit-content;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 0 16px;
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: $colorWhite;
    flex-shrink: 0;

    &--Red {
        background-color: $colorRed50;
    }

    &--Black {
        background-color: $colorBlack;
    }

    &--Green {
        background-color: $colorGreen;
        color: $colorBlack;
    }

}